.Landing {
}

.headerText {
    opacity: 1;
    color: #000000;
}

.headerBg {
    /*background-image: url('https://unsplash.it/1900/1080?image=1076');*/
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.bg-image-full {
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.mailIcon {
    width: 18px;
    height: auto;
    position: absolute;
}
.mailIcon:hover {
    cursor: pointer;
}
.footerText {
    font-size: 6px;
    text-align: center;
    margin: 0 auto;
    position: relative;
}
.footer {
    position: relative;
    margin-top: -150px; /* negative value of footer height */
    height: 150px;
    clear: both;
    padding-top: 20px;
}
.Landing {
}

.headerText {
    opacity: 1;
    color: #000000;
}

.headerBg {
    /*background-image: url('https://unsplash.it/1900/1080?image=1076');*/
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.bg-image-full {
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.mailIcon {
    width: 18px;
    height: auto;
    position: absolute;
}
.mailIcon:hover {
    cursor: pointer;
}
.footerText {
    font-size: 6px;
    text-align: center;
    margin: 0 auto;
    position: relative;
}
.footer {
    position: relative;
    margin-top: -150px; /* negative value of footer height */
    height: 150px;
    clear: both;
    padding-top: 20px;
}
